// GET PRIVACY POLICY CONTENT
export const GET_CONTACT_US = "GET_CONTACT_US";
export const GET_CONTACT_US_ERROR = "GET_CONTACT_US_ERROR";
export const GET_CONTACT_US_LOADING = "GET_CONTACT_US_LOADING";
// CLEAR REDUCER STATE
export const CONTACT_US_STATE_CLEAR = "CONTACT_US_STATE_CLEAR";
// ADD CONTACT_US
export const ADD_CONTACT_US_SUCCESS = "ADD_CONTACT_US_SUCCESS";
export const ADD_CONTACT_US_ERROR = "ADD_CONTACT_US_ERROR";
//UPDATE CONTACT_US
export const UPDATE_CONTACT_US_SUCCESS = "UPDATE_CONTACT_US_SUCCESS";
export const UPDATE_CONTACT_US_ERROR = "UPDATE_CONTACT_US_ERROR";
