// GET PRIVACY POLICY CONTENT
export const GET_PRIVACY_POLICY = "GET_PRIVACY_POLICY";
export const GET_PRIVACY_POLICY_ERROR = "GET_PRIVACY_POLICY_ERROR";
export const GET_PRIVACY_POLICY_LOADING = "GET_PRIVACY_POLICY_LOADING";
// CLEAR REDUCER STATE
export const PRIVACY_POLICY_STATE_CLEAR = "PRIVACY_POLICY_STATE_CLEAR";
// ADD PRIVACY_POLICY
export const ADD_PRIVACY_POLICY_SUCCESS = "ADD_PRIVACY_POLICY_SUCCESS";
export const ADD_PRIVACY_POLICY_ERROR = "ADD_PRIVACY_POLICY_ERROR";
//UPDATE PRIVACY_POLICY
export const UPDATE_PRIVACY_POLICY_SUCCESS = "UPDATE_PRIVACY_POLICY_SUCCESS";
export const UPDATE_PRIVACY_POLICY_ERROR = "UPDATE_PRIVACY_POLICY_ERROR";
