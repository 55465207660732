import createReducer from "../../reducers/createReducer";
import * as types from "./types";
let initialState = {
  contactUsContent: null,
  loading: true,
  addContactUsResult: null,
  error: null,
  updateContactUsResult: null,
};

export const contactUsReducer = createReducer(initialState, {
  [types.GET_CONTACT_US](state, action) {
    return Object.assign({}, state, {
      contactUsContent: action.payload,
      error: null,
      loading: false,
    });
  },
  [types.GET_CONTACT_US_ERROR](state, action) {
    return Object.assign({}, state, {
      contactUsContent: null,
      loading: false,
    });
  },
  [types.GET_CONTACT_US_LOADING](state, action) {
    return Object.assign({}, state, {
      loading: true,
    });
  },
  [types.ADD_CONTACT_US_SUCCESS](state, action) {
    return Object.assign({}, state, {
      addContactUsResult: action.payload,
      error: null,
    });
  },
  [types.ADD_CONTACT_US_ERROR](state, action) {
    return Object.assign({}, state, {
      error: action.payload,
      addContactUsResult: null,
    });
  },
  [types.UPDATE_CONTACT_US_SUCCESS](state, action) {
    return Object.assign({}, state, {
      error: null,
      updateContactUsResult: action.payload,
    });
  },
  [types.UPDATE_CONTACT_US_ERROR](state, action) {
    return Object.assign({}, state, {
      error: action.payload,
      updateContactUsResult: null,
    });
  },
  [types.CONTACT_US_STATE_CLEAR](state, action) {
    return Object.assign({}, state, {
      addContactUsResult: null,
      error: null,
      updateContactUsResult: null,
      loading: false,
    });
  },
});
