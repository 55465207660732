import createReducer from "../../reducers/createReducer";
import * as types from "./types";
let initialState = {
  privacyContent: null,
  loading: true,
  addPrivacyContentResult: null,
  error: null,
  updatePrivacyContentResult: null,
};

export const privcyPolicyReducer = createReducer(initialState, {
  [types.GET_PRIVACY_POLICY](state, action) {
    return Object.assign({}, state, {
      privacyContent: action.payload,
      error: null,
      loading: false,
    });
  },
  [types.GET_PRIVACY_POLICY_ERROR](state, action) {
    return Object.assign({}, state, {
      privacyContent: null,
      loading: false,
    });
  },
  [types.GET_PRIVACY_POLICY_LOADING](state, action) {
    return Object.assign({}, state, {
      loading: true,
    });
  },
  [types.ADD_PRIVACY_POLICY_SUCCESS](state, action) {
    return Object.assign({}, state, {
      addPrivacyContentResult: action.payload,
      error: null,
    });
  },
  [types.ADD_PRIVACY_POLICY_ERROR](state, action) {
    return Object.assign({}, state, {
      error: action.payload,
      addPrivacyContentResult: null,
    });
  },
  [types.UPDATE_PRIVACY_POLICY_SUCCESS](state, action) {
    return Object.assign({}, state, {
      error: null,
      updatePrivacyContentResult: action.payload,
    });
  },
  [types.UPDATE_PRIVACY_POLICY_ERROR](state, action) {
    return Object.assign({}, state, {
      error: action.payload,
      updatePrivacyContentResult: null,
    });
  },
  [types.PRIVACY_POLICY_STATE_CLEAR](state, action) {
    return Object.assign({}, state, {
      addPrivacyContentResult: null,
      error: null,
      updatePrivacyContentResult: null,
      loading: false,
    });
  },
});
