import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";
import React from "react";
import { Provider } from "react-redux";
import store from "./store";
import { Spinner } from "reactstrap";

const loading = () => (
  <div className="overlay-div">
    <Spinner
      style={{
        width: "5rem",
        height: "5rem",
      }}
    />{" "}
  </div>
);

const DefaultLayout = React.lazy(() => import("./containers/DefaultLayout"));
const Login = React.lazy(() => import("./views/Login"));
const NotFound = React.lazy(() => import("./views/Page404"));

function MainRouter() {
  return (
    <Provider store={store}>
      <Router basename="/">
        <React.Suspense fallback={loading()}>
          <Switch>
            <Route path="/login" render={(props) => <Login {...props} />} />
            <Route
              path="/"
              render={(props) =>
                localStorage.getItem("userId") &&
                localStorage.getItem("token") ? (
                  <DefaultLayout {...props} />
                ) : (
                  <Redirect to="/login"></Redirect>
                )
              }
            />
            <Route path="*" component={NotFound} />
          </Switch>
        </React.Suspense>
      </Router>
    </Provider>
  );
}

export default MainRouter;
