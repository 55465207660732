import createReducer from "../../reducers/createReducer";
import * as types from "./types";

let initialState = {
  addOnPriceList: [],
  addOnPriceDetail: null,
  loading: false,
  upsertResult: null,
  error: null,
  deleteResult: null,
};

export const addOnPriceReducer = createReducer(initialState, {
  [types.GET_ADD_ON_PRICE_LIST](state, action) {
    return Object.assign({}, state, {
      addOnPriceList: action.payload,
      error: null,
      loading: false,
    });
  },
  [types.GET_ADD_ON_PRICE_LIST_ERROR](state, action) {
    return Object.assign({}, state, {
      error: action.payload,
      addOnPriceList: [],
      loading: false,
    });
  },
  [types.GET_ADD_ON_PRICE_LIST_LOADING](state, action) {
    return Object.assign({}, state, {
      loading: true,
    });
  },
  [types.GET_ADD_ON_PRICE_DETAIL_SUCCESS](state, action) {
    return Object.assign({}, state, {
      addOnPriceDetail: action.payload,
      error: null,
      loading: false,
    });
  },
  [types.GET_ADD_ON_PRICE_DETAIL_ERROR](state, action) {
    return Object.assign({}, state, {
      error: action.payload,
      addOnPriceDetail: null,
      loading: false,
    });
  },
  [types.UPSERT_ADD_ON_PRICE_SUCCESS](state, action) {
    return Object.assign({}, state, {
      upsertResult: action.payload,
      error: null,
    });
  },
  [types.UPSERT_ADD_ON_PRICE_ERROR](state, action) {
    return Object.assign({}, state, {
      error: action.payload,
      upsertResult: null,
    });
  },
  [types.DELETE_ADD_ON_PRICE_SUCCESS](state, action) {
    return Object.assign({}, state, {
      deleteResult: action.payload,
      error: null,
    });
  },
  [types.DELETE_ADD_ON_PRICE_ERROR](state, action) {
    return Object.assign({}, state, {
      deleteResult: null,
      error: action.payload,
    });
  },
  [types.ADD_ON_PRICE_STATE_CLEAR](state, action) {
    return Object.assign({}, state, {
      loading: false,
      upsertResult: null,
      error: null,
      deleteResult: null,
    });
  },
  [types.CLEAR_ADD_ON_PRICE_DETAIL](state, action) {
    return Object.assign({}, state, {
      loading: false,
      addOnPriceDetail: null,
      error: null,
    });
  },
});
