import createReducer from "../../reducers/createReducer";
import * as types from "./types";
let initialState = {
  userList: [],
  loading: true,
  error: null,
  updateUserResult: null,
  deleteUserResult: null,
  blockUserResult: null,
  updatePassword: null,
};

export const userReducer = createReducer(initialState, {
  [types.GET_USERS](state, action) {
    return Object.assign({}, state, {
      userList: action.payload,
      error: null,
      loading: false,
    });
  },
  [types.GET_USERS_ERROR](state, action) {
    return Object.assign({}, state, {
      error: action.payload,
      userList: [],
      loading: false,
    });
  },
  [types.GET_USERS_LOADING](state, action) {
    return Object.assign({}, state, {
      loading: true,
    });
  },
  [types.CHANGE_PASSWORD_SUCCESS](state, action) {
    return Object.assign({}, state, {
      error: null,
      updatePassword: action.payload,
    });
  },
  [types.CHANGE_PASSWORD_ERROR](state, action) {
    return Object.assign({}, state, {
      error: action.payload,
      updatePassword: null,
    });
  },
  [types.UPDATE_USER_SUCCESS](state, action) {
    return Object.assign({}, state, {
      error: null,
      updateUserResult: action.payload,
    });
  },
  [types.UPDATE_USER_ERROR](state, action) {
    return Object.assign({}, state, {
      error: action.payload,
      updateUserResult: null,
    });
  },
  [types.DELETE_USER_SUCCESS](state, action) {
    return Object.assign({}, state, {
      deleteUserResult: action.payload,
      error: null,
    });
  },
  [types.DELETE_USER_ERROR](state, action) {
    return Object.assign({}, state, {
      deleteUserResult: null,
      error: action.payload,
    });
  },
  [types.BLOCK_USER_SUCCESS](state, action) {
    return Object.assign({}, state, {
      blockUserResult: action.payload,
      error: null,
    });
  },
  [types.BLOCK_USER_ERROR](state, action) {
    return Object.assign({}, state, {
      blockUserResult: null,
      error: action.payload,
    });
  },
  [types.USER_STATE_CLEAR](state, action) {
    return Object.assign({}, state, {
      error: null,
      updateUserResult: null,
      deleteUserResult: null,
      loading: false,
      blockUserResult: null,
      updatePassword: null,
    });
  },
});
