import createReducer from "../../reducers/createReducer";
import * as types from "./types";
let initialState = {
  addOnList: [],
  loading: true,
  addAddOnResult: null,
  error: null,
  updateAddOnResult: null,
  deleteAddOnResult: null,
  deleteAddOnErrorRes: null,
};

export const addOnReducer = createReducer(initialState, {
  [types.GET_ADD_ON_LIST](state, action) {
    return Object.assign({}, state, {
      addOnList: action.payload,
      error: null,
      loading: false,
    });
  },
  [types.GET_ADD_ON_LIST_ERROR](state, action) {
    return Object.assign({}, state, {
      error: action.payload,
      addOnList: [],
      loading: false,
    });
  },
  [types.GET_ADD_ON_LIST_LOADING](state, action) {
    return Object.assign({}, state, {
      loading: true,
    });
  },
  [types.ADD_ADD_ON_SUCCESS](state, action) {
    return Object.assign({}, state, {
      addAddOnResult: action.payload,
      error: null,
    });
  },
  [types.ADD_ADD_ON_ERROR](state, action) {
    return Object.assign({}, state, {
      error: action.payload,
      addAddOnResult: null,
    });
  },
  [types.UPDATE_ADD_ON_SUCCESS](state, action) {
    return Object.assign({}, state, {
      error: null,
      updateAddOnResult: action.payload,
    });
  },
  [types.UPDATE_ADD_ON_ERROR](state, action) {
    return Object.assign({}, state, {
      error: action.payload,
      updateAddOnResult: null,
    });
  },
  [types.DELETE_ADD_ON_SUCCESS](state, action) {
    return Object.assign({}, state, {
      deleteAddOnResult: action.payload,
      error: null,
    });
  },
  [types.DELETE_ADD_ON_ERROR](state, action) {
    return Object.assign({}, state, {
      deleteAddOnResult: null,
      error: action.payload.result ? null : action.payload.message,
      deleteAddOnErrorRes: action.payload.result,
    });
  },
  [types.ADD_ON_STATE_CLEAR](state, action) {
    return Object.assign({}, state, {
      addAddOnResult: null,
      error: null,
      updateAddOnResult: null,
      deleteAddOnResult: null,
      loading: false,
      deleteAddOnErrorRes: null,
    });
  },
  [types.ADD_ON_LIST_STATE_CLEAR](state, action) {
    return Object.assign({}, state, {
      addOnList: [],
      loading: false,
    });
  },
});
