import createReducer from "../../reducers/createReducer";
import * as types from "./types";
let initialState = {
  termsContent: null,
  loading: true,
  addTermsContentResult: null,
  error: null,
  updateTermsContentResult: null,
};

export const termConditionReducer = createReducer(initialState, {
  [types.GET_TERMS_AND_CONDITION](state, action) {
    return Object.assign({}, state, {
      termsContent: action.payload,
      error: null,
      loading: false,
    });
  },
  [types.GET_TERMS_AND_CONDITION_ERROR](state, action) {
    return Object.assign({}, state, {
      termsContent:null,
      loading: false,
    });
  },
  [types.GET_TERMS_AND_CONDITION_LOADING](state, action) {
    return Object.assign({}, state, {
      loading: true,
    });
  },
  [types.ADD_TERMS_AND_CONDITION_SUCCESS](state, action) {
    return Object.assign({}, state, {
      addTermsContentResult: action.payload,
      error: null,
    });
  },
  [types.ADD_TERMS_AND_CONDITION_ERROR](state, action) {
    return Object.assign({}, state, {
      error: action.payload,
      addTermsContentResult: null,
    });
  },
  [types.UPDATE_TERMS_AND_CONDITION_SUCCESS](state, action) {
    return Object.assign({}, state, {
      error: null,
      updateTermsContentResult: action.payload,
    });
  },
  [types.UPDATE_TERMS_AND_CONDITION_ERROR](state, action) {
    return Object.assign({}, state, {
      error: action.payload,
      updateTermsContentResult: null,
    });
  },
  [types.TERMS_AND_CONDITION_STATE_CLEAR](state, action) {
    return Object.assign({}, state, {
      addTermsContentResult: null,
      error: null,
      updateTermsContentResult: null,
      loading: false,
    });
  },
});
