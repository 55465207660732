// GET CARTYPE LIST
export const GET_INTERIOR_EXTERIOR_TYPES = "GET_INTERIOR_EXTERIOR_TYPES";
export const GET_INTERIOR_EXTERIOR_TYPES_ERROR =
  "GET_INTERIOR_EXTERIOR_TYPES_ERROR";
export const GET_INTERIOR_EXTERIOR_TYPES_LOADING =
  "GET_INTERIOR_EXTERIOR_TYPES_LOADING";
// CLEAR REDUCER STATE
export const INTERIOR_EXTERIOR_TYPES_CLEAR = "INTERIOR_EXTERIOR_TYPES_CLEAR";
// ADD INTERIOR_EXTERIOR
export const ADD_INTERIOR_EXTERIOR_SUCCESS = "ADD_INTERIOR_EXTERIOR_SUCCESS";
export const ADD_INTERIOR_EXTERIOR_ERROR = "ADD_INTERIOR_EXTERIOR_ERROR";
//UPDATE INTERIOR_EXTERIOR
export const UPDATE_INTERIOR_EXTERIOR_SUCCESS =
  "UPDATE_INTERIOR_EXTERIOR_SUCCESS";
export const UPDATE_INTERIOR_EXTERIOR_ERROR = "UPDATE_INTERIOR_EXTERIOR_ERROR";
//DELETE INTERIOR_EXTERIOR
export const DELETE_INTERIOR_EXTERIOR_SUCCESS =
  "DELETE_INTERIOR_EXTERIOR_SUCCESS";
export const DELETE_INTERIOR_EXTERIOR_ERROR = "DELETE_INTERIOR_EXTERIOR_ERROR";
