// GET USERS LIST
export const GET_USERS = "GET_USERS";
export const GET_USERS_ERROR = "GET_USERS_ERROR";
export const GET_USERS_LOADING = "GET_USERS_LOADING";
// CLEAR REDUCER STATE
export const USER_STATE_CLEAR = "USER_STATE_CLEAR";

//UPDATE USER
export const UPDATE_USER_SUCCESS = "UPDATE_USER_SUCCESS";
export const UPDATE_USER_ERROR = "UPDATE_USER_ERROR";
//DELETE USER
export const DELETE_USER_SUCCESS = "DELETE_USER_SUCCESS";
export const DELETE_USER_ERROR = "DELETE_USER_ERROR";
//BOLCK USER
export const BLOCK_USER_SUCCESS = "BLOCK_USER_SUCCESS";
export const BLOCK_USER_ERROR = "BLOCK_USER_ERROR";
// CHANGE PASSWORD
export const CHANGE_PASSWORD_SUCCESS = "CHANGE_PASSWORD_SUCCESS";
export const CHANGE_PASSWORD_ERROR = "CHANGE_PASSWORD_ERROR";
