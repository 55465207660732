import { combineReducers } from "redux";
import * as userReducer from "../views/UserList/reducer";
import * as loginReducer from "../views/Login/reducer";
import * as carTypeReducer from "../views/CarType/reducer";
import * as addOnReducer from "../views/Addon/reducer";
import * as termConditionReducer from "../views/TermsAndCondition/reducer";
import * as privcyPolicyReducer from "../views/PrivacyPolicy/reducer";
import * as salesManagersReducer from "../views/SalesManager/reducer";
import * as addressReducer from "../views/UserAddress/reducer";
import * as addOnPriceReducer from "../views/AddOnPricing/reducer";
import * as serviceProviderReducer from "../views/ServiceProvider/reducer";
import * as orderReducer from "../views/Order/reducer";
import * as contactUsReducer from "../views/contactUs/reducer";
import * as settingsReducer from "../views/settings/reducer";
import * as interiorExteriorReducer from "../views/InteriorExterior/reducer";
import * as packagesReducer from "../views/Packages/reducer";

export default combineReducers(
  Object.assign(
    {},
    userReducer,
    loginReducer,
    carTypeReducer,
    addOnReducer,
    termConditionReducer,
    privcyPolicyReducer,
    salesManagersReducer,
    addressReducer,
    addOnPriceReducer,
    serviceProviderReducer,
    orderReducer,
    contactUsReducer,
    settingsReducer,
    interiorExteriorReducer,
    packagesReducer
  )
);
