import createReducer from "../../reducers/createReducer";
import * as types from "./types";
let initialState = {
  addressList: [],
  loading: true,
  error: null,
};

export const addressReducer = createReducer(initialState, {
  [types.GET_USER_ADDRESSES](state, action) {
    return Object.assign({}, state, {
      addressList: action.payload,
      error: null,
      loading: false,
    });
  },
  [types.GET_USER_ADDRESSES_ERROR](state, action) {
    return Object.assign({}, state, {
      error: action.payload,
      addressList: [],
      loading: false,
    });
  },
  [types.GET_USER_ADDRESSES_LOADING](state, action) {
    return Object.assign({}, state, {
      loading: true,
    });
  },
});
