import createReducer from "../../reducers/createReducer";
import * as types from "./types";
let initialState = {
  salesManagerList: [],
  loading: true,
  addSalesManagerResult: null,
  error: null,
  updateSalesManagerResult: null,
  deleteUserResult: null,
  blockUserResult:null
};

export const salesManagersReducer = createReducer(initialState, {
  [types.GET_SALES_MANAGERS](state, action) {
    return Object.assign({}, state, {
      salesManagerList: action.payload,
      error: null,
      loading: false,
    });
  },
  [types.GET_SALES_MANAGERS_ERROR](state, action) {
    return Object.assign({}, state, {
      error: action.payload,
      salesManagerList: [],
      loading: false,
    });
  },
  [types.GET_SALES_MANAGERS_LOADING](state, action) {
    return Object.assign({}, state, {
      loading: true,
    });
  },
  [types.ADD_SALAES_MANAGER_SUCCESS](state, action) {
    return Object.assign({}, state, {
      addSalesManagerResult: action.payload,
      error: null,
    });
  },
  [types.ADD_SALAES_MANAGER_ERROR](state, action) {
    return Object.assign({}, state, {
      error: action.payload,
      addSalesManagerResult: null,
    });
  },
  [types.UPDATE_SALAES_MANAGER_SUCCESS](state, action) {
    return Object.assign({}, state, {
      error: null,
      updateSalesManagerResult: action.payload,
    });
  },
  [types.UPDATE_SALAES_MANAGER_ERROR](state, action) {
    return Object.assign({}, state, {
      error: action.payload,
      updateSalesManagerResult: null,
    });
  },
  [types.DELETE_SALAES_MANAGER_SUCCESS](state, action) {
    return Object.assign({}, state, {
      deleteUserResult: action.payload,
      error: null,
    });
  },
  [types.DELETE_SALAES_MANAGER_ERROR](state, action) {
    return Object.assign({}, state, {
      deleteUserResult: null,
      error: action.payload,
    });
  },
    [types.BLOCK_SALAES_MANAGER_SUCCESS](state, action) {
    return Object.assign({}, state, {
      blockUserResult: action.payload,
      error: null,
    });
  },
  [types.BLOCK_SALAES_MANAGER_ERROR](state, action) {
    return Object.assign({}, state, {
      blockUserResult: null,
      error: action.payload,
    });
  },
  [types.SALAES_MANAGER_STATE_CLEAR](state, action) {
    return Object.assign({}, state, {
      addSalesManagerResult: null,
      error: null,
      updateSalesManagerResult: null,
      deleteUserResult: null,
      loading: false,
    });
  },
});
