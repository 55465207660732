// GET PACKAGE LIST
export const GET_PACKAGES = "GET_PACKAGES";
export const GET_PACKAGES_ERROR = "GET_PACKAGES_ERROR";
export const GET_PACKAGES_LOADING = "GET_PACKAGES_LOADING";
// CLEAR REDUCER STATE
export const PACKAGES_STATE_CLEAR = "PACKAGES_STATE_CLEAR";
// ADD PACKAGE
export const ADD_PACKAGES_SUCCESS = "ADD_PACKAGES_SUCCESS";
export const ADD_PACKAGES_ERROR = "ADD_PACKAGES_ERROR";
//UPDATE PACKAGE
export const UPDATE_PACKAGE_SUCCESS = "UPDATE_PACKAGE_SUCCESS";
export const UPDATE_PACKAGE_ERROR = "UPDATE_PACKAGE_ERROR";
//DELETE PACKAGE
export const DELETE_PACKAGE_SUCCESS = "DELETE_PACKAGE_SUCCESS";
export const DELETE_PACKAGE_ERROR = "DELETE_PACKAGE_ERROR";