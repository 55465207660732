// GET TERMS_AND_CONDITION CONTENT
export const GET_TERMS_AND_CONDITION = "GET_TERMS_AND_CONDITION";
export const GET_TERMS_AND_CONDITION_ERROR = "GET_TERMS_AND_CONDITION_ERROR";
export const GET_TERMS_AND_CONDITION_LOADING =
  "GET_TERMS_AND_CONDITION_LOADING";
// CLEAR REDUCER STATE
export const TERMS_AND_CONDITION_STATE_CLEAR =
  "TERMS_AND_CONDITION_STATE_CLEAR";
// ADD TERMS_AND_CONDITION
export const ADD_TERMS_AND_CONDITION_SUCCESS =
  "ADD_TERMS_AND_CONDITION_SUCCESS";
export const ADD_TERMS_AND_CONDITION_ERROR = "ADD_TERMS_AND_CONDITION_ERROR";
//UPDATE TERMS_AND_CONDITION
export const UPDATE_TERMS_AND_CONDITION_SUCCESS =
  "UPDATE_TERMS_AND_CONDITION_SUCCESS";
export const UPDATE_TERMS_AND_CONDITION_ERROR =
  "UPDATE_TERMS_AND_CONDITION_ERROR";
