import createReducer from "../../reducers/createReducer";
import * as types from "./types";
let initialState = {
  interior: [],
  exterior: [],
  loading: true,
  addInteriorExteriorResult: null,
  error: null,
  updateInteriorExteriorResult: null,
  deleteInteriorExteriorResult: null,
  deleteTypeErrorRes: null,
};

export const interiorExteriorReducer = createReducer(initialState, {
  [types.GET_INTERIOR_EXTERIOR_TYPES](state, action) {
    return Object.assign({}, state, {
      interior:
        (action.payload.length &&
          action.payload.filter((d) => d.type === "interior")) ||
        [],
      exterior:
        (action.payload.length &&
          action.payload.filter((d) => d.type === "exterior")) ||
        [],
      error: null,
      loading: false,
    });
  },
  [types.GET_INTERIOR_EXTERIOR_TYPES_ERROR](state, action) {
    return Object.assign({}, state, {
      error: action.payload,
      interior: [],
      exterior: [],
      loading: false,
    });
  },
  [types.GET_INTERIOR_EXTERIOR_TYPES_LOADING](state, action) {
    return Object.assign({}, state, {
      loading: true,
    });
  },
  [types.ADD_INTERIOR_EXTERIOR_SUCCESS](state, action) {
    return Object.assign({}, state, {
      addInteriorExteriorResult: action.payload,
      error: null,
      loading: false,
    });
  },
  [types.ADD_INTERIOR_EXTERIOR_ERROR](state, action) {
    return Object.assign({}, state, {
      error: action.payload,
      addInteriorExteriorResult: null,
      loading: false,
    });
  },
  [types.UPDATE_INTERIOR_EXTERIOR_SUCCESS](state, action) {
    return Object.assign({}, state, {
      error: null,
      updateInteriorExteriorResult: action.payload,
      loading: false,
    });
  },
  [types.UPDATE_INTERIOR_EXTERIOR_ERROR](state, action) {
    return Object.assign({}, state, {
      error: action.payload,
      updateInteriorExteriorResult: null,
      loading: false,
    });
  },
  [types.DELETE_INTERIOR_EXTERIOR_SUCCESS](state, action) {
    return Object.assign({}, state, {
      deleteInteriorExteriorResult: action.payload,
      error: null,
    });
  },
  [types.DELETE_INTERIOR_EXTERIOR_ERROR](state, action) {
    return Object.assign({}, state, {
      deleteInteriorExteriorResult: null,
      error: action.payload.message,
      deleteTypeErrorRes: action.payload.result,
    });
  },
  [types.INTERIOR_EXTERIOR_TYPES_CLEAR](state, action) {
    return Object.assign({}, state, {
      addInteriorExteriorResult: null,
      error: null,
      updateInteriorExteriorResult: null,
      deleteInteriorExteriorResult: null,
      loading: false,
      deleteCarErrorRes: null,
    });
  },
});
