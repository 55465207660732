// GET ADD-ON LIST
export const GET_ADD_ON_LIST = "GET_ADD_ON_LIST";
export const GET_ADD_ON_LIST_ERROR = "GET_ADD_ON_LIST_ERROR";
export const GET_ADD_ON_LIST_LOADING = "GET_ADD_ON_LIST_LOADING";
// CLEAR REDUCER STATE
export const ADD_ON_STATE_CLEAR = "ADD_ON_STATE_CLEAR";
// ADD ADD_ON
export const ADD_ADD_ON_SUCCESS = "ADD_ADD_ON_SUCCESS";
export const ADD_ADD_ON_ERROR = "ADD_ADD_ON_ERROR";
//UPDATE ADD_ON
export const UPDATE_ADD_ON_SUCCESS = "UPDATE_ADD_ON_SUCCESS";
export const UPDATE_ADD_ON_ERROR = "UPDATE_ADD_ON_ERROR";
//DELETE ADD_ON
export const DELETE_ADD_ON_SUCCESS = "DELETE_ADD_ON_SUCCESS";
export const DELETE_ADD_ON_ERROR = "DELETE_ADD_ON_ERROR";
// GET_ADD_ON_LISTERROR
export const ADD_ON_LIST_STATE_CLEAR = "ADD_ON_LIST_STATE_CLEAR";