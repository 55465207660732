// GET ORDER LIST
export const GET_ORDER_LIST = "GET_ORDER_LIST";
export const GET_ORDER_LIST_ERROR = "GET_ORDER_LIST_ERROR";
export const GET_ORDER_LIST_LOADING = "GET_ORDER_LIST_LOADING";

// GET ORDER 
export const GET_ORDER = "GET_ORDER";
export const GET_ORDER_ERROR = "GET_ORDER_ERROR";
export const GET_ORDER_LOADING = "GET_ORDER_LIST_LOADING";

// update status
export const UPDATE_ORDER_STATUS = "UPDATE_ORDER_STATUS";
export const UPDATE_ORDER_STATUS_ERROR ="UPDATE_ORDER_STATUS_ERROR"