import createReducer from "../../reducers/createReducer";
import * as types from "./types";
let initialState = {
  settings: null,
  loading: true,
  addSettingsResult: null,
  error: null,
  updateSettingsResult: null,
};

export const settingsReducer = createReducer(initialState, {
  [types.GET_SETTINGS](state, action) {
    return Object.assign({}, state, {
      settings: action.payload,
      error: null,
      loading: false,
    });
  },
  [types.GET_SETTINGS_ERROR](state, action) {
    return Object.assign({}, state, {
      settings: null,
      loading: false,
    });
  },
  [types.GET_SETTINGS_LOADING](state, action) {
    return Object.assign({}, state, {
      loading: true,
    });
  },
  [types.ADD_SETTINGS_SUCCESS](state, action) {
    return Object.assign({}, state, {
      addSettingsResult: action.payload,
      error: null,
    });
  },
  [types.ADD_SETTINGS_ERROR](state, action) {
    return Object.assign({}, state, {
      error: action.payload,
      addSettingsResult: null,
    });
  },
  [types.UPDATE_SETTINGS_SUCCESS](state, action) {
    return Object.assign({}, state, {
      error: null,
      updateSettingsResult: action.payload,
    });
  },
  [types.UPDATE_SETTINGS_ERROR](state, action) {
    return Object.assign({}, state, {
      error: action.payload,
      updateSettingsResult: null,
    });
  },
  [types.SETTINGS_STATE_CLEAR](state, action) {
    return Object.assign({}, state, {
      addSettingsResult: null,
      error: null,
      updateSettingsResult: null,
      loading: false,
    });
  },
});
