export const GET_ADD_ON_PRICE_LIST = "GET_ADD_ON_PRICE_LIST";
export const GET_ADD_ON_PRICE_LIST_ERROR = "GET_ADD_ON_PRICE_LIST_ERROR";
export const GET_ADD_ON_PRICE_LIST_LOADING = "GET_ADD_ON_PRICE_LIST_LOADING";
// CLEAR REDUCER STATE
export const ADD_ON_PRICE_STATE_CLEAR = "ADD_ON_PRICE_STATE_CLEAR";
// ADD ADD_ON_PRICE
export const UPSERT_ADD_ON_PRICE_SUCCESS = "UPSERT_ADD_ON_PRICE_SUCCESS";
export const UPSERT_ADD_ON_PRICE_ERROR = "UPSERT_ADD_ON_PRICE_ERROR";
//GET DETAILS
export const GET_ADD_ON_PRICE_DETAIL = "GET_ADD_ON_PRICE_DETAIL";
export const GET_ADD_ON_PRICE_DETAIL_SUCCESS =
  "GET_ADD_ON_PRICE_DETAIL_SUCCESS";
export const GET_ADD_ON_PRICE_DETAIL_ERROR = "GET_ADD_ON_PRICE_DETAIL_ERROR";
//DELETE ADD_ON_PRICE
export const DELETE_ADD_ON_PRICE_SUCCESS = "DELETE_ADD_ON_PRICE_SUCCESS";
export const DELETE_ADD_ON_PRICE_ERROR = "DELETE_ADD_ON_PRICE_ERROR";
//CLEAR DETAILS
export const CLEAR_ADD_ON_PRICE_DETAIL = "CLEAR_ADD_ON_PRICE_DETAIL";
