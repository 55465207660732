// GET SERVICEPROVIDER LIST
export const GET_SERVICE_PROVIDERS = "GET_SERVICE_PROVIDERS";
export const GET_SERVICE_PROVIDERS_ERROR = "GET_SERVICE_PROVIDERS_ERROR";
export const GET_SERVICE_PROVIDERS_LOADING = "GET_SERVICE_PROVIDERS_LOADING";
// CLEAR REDUCER STATE
export const SERVICE_PROVIDER_STATE_CLEAR = "SERVICE_PROVIDER_STATE_CLEAR";
// ADD SERVICE PROVIDER
export const ADD_SERVICE_PROVIDER_SUCCESS = "ADD_SERVICE_PROVIDER_SUCCESS";
export const ADD_SERVICE_PROVIDER_ERROR = "ADD_SERVICE_PROVIDER_ERROR";
//UPDATE SERVICE PROVIDER
export const UPDATE_SERVICE_PROVIDER_SUCCESS = "UPDATE_SERVICE_PROVIDER_SUCCESS";
export const UPDATE_SERVICE_PROVIDER_ERROR = "UPDATE_SERVICE_PROVIDER_ERROR";
//DELETE SERVICE PROVIDER
export const DELETE_SERVICE_PROVIDER_SUCCESS = "DELETE_SERVICE_PROVIDER_SUCCESS";
export const DELETE_SERVICE_PROVIDER_ERROR = "DELETE_SERVICE_PROVIDER_ERROR";
//BLOCL SERVICE PROVIDER
export const BLOCK_SERVICE_PROVIDER_SUCCESS = "BLOCK_SERVICE_PROVIDER_SUCCESS";
export const BLOCK_SERVICE_PROVIDER_ERROR = "BLOCK_SERVICE_PROVIDER_ERROR";