import createReducer from "../../reducers/createReducer";
import * as types from "./types";
let initialState = {
  carTypeList: [],
  loading: true,
  addCarTypeResult: null,
  error: null,
  updateCarTypeResult: null,
  deleteCarTypeResult: null,
  deleteCarErrorRes:null
};

export const carTypeReducer = createReducer(initialState, {
  [types.GET_CAR_TYPES](state, action) {
    return Object.assign({}, state, {
      carTypeList: action.payload,
      error: null,
      loading: false,
    });
  },
  [types.GET_CAR_TYPES_ERROR](state, action) {
    return Object.assign({}, state, {
      error: action.payload,
      carTypeList: [],
      loading: false,
    });
  },
  [types.GET_CAR_TYPES_LOADING](state, action) {
    return Object.assign({}, state, {
      loading: true,
    });
  },
  [types.ADD_CARTYPE_SUCCESS](state, action) {
    return Object.assign({}, state, {
      addCarTypeResult: action.payload,
      error: null,
      loading:false
    });
  },
  [types.ADD_CARTYPE_ERROR](state, action) {
    return Object.assign({}, state, {
      error: action.payload,
      addCarTypeResult: null,
      loading:false
    });
  },
  [types.UPDATE_CARTYPE_SUCCESS](state, action) {
    return Object.assign({}, state, {
      error: null,
      updateCarTypeResult: action.payload,
      loading:false
    });
  },
  [types.UPDATE_CARTYPE_ERROR](state, action) {
    return Object.assign({}, state, {
      error: action.payload,
      updateCarTypeResult: null,
      loading:false
    });
  },
  [types.DELETE_CARTYPE_SUCCESS](state, action) {
    return Object.assign({}, state, {
      deleteCarTypeResult: action.payload,
      error: null,
    });
  },
  [types.DELETE_CARTYPE_ERROR](state, action) {
    return Object.assign({}, state, {
      deleteCarTypeResult: null,
      error: action.payload.message,
      deleteCarErrorRes:action.payload.result
    });
  },
  [types.CARTYPE_STATE_CLEAR](state, action) {
    return Object.assign({}, state, {
      addCarTypeResult: null,
      error: null,
      updateCarTypeResult: null,
      deleteCarTypeResult: null,
      loading: false,
      deleteCarErrorRes:null
    });
  },
});
