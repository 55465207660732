import createReducer from "../../reducers/createReducer";
import * as types from "./types";
let initialState = {
  serviceProviderList: [],
  loading: true,
  addServiceProvideresult: null,
  error: null,
  updateServiceProvideresult: null,
  deleteServiceProviderResult: null,
};

export const serviceProviderReducer = createReducer(initialState, {
  [types.GET_SERVICE_PROVIDERS](state, action) {
    return Object.assign({}, state, {
      serviceProviderList: action.payload,
      error: null,
      loading: false,
    });
  },
  [types.GET_SERVICE_PROVIDERS_ERROR](state, action) {
    return Object.assign({}, state, {
      error: action.payload,
      serviceProviderList: [],
      loading: false,
    });
  },
  [types.GET_SERVICE_PROVIDERS_LOADING](state, action) {
    return Object.assign({}, state, {
      loading: true,
    });
  },
  [types.ADD_SERVICE_PROVIDER_SUCCESS](state, action) {
    return Object.assign({}, state, {
      addServiceProvideresult: action.payload,
      error: null,
    });
  },
  [types.ADD_SERVICE_PROVIDER_ERROR](state, action) {
    return Object.assign({}, state, {
      error: action.payload,
      addServiceProvideresult: null,
    });
  },
  [types.UPDATE_SERVICE_PROVIDER_SUCCESS](state, action) {
    return Object.assign({}, state, {
      error: null,
      updateServiceProvideresult: action.payload,
    });
  },
  [types.UPDATE_SERVICE_PROVIDER_ERROR](state, action) {
    return Object.assign({}, state, {
      error: action.payload,
      updateServiceProvideresult: null,
    });
  },
  [types.DELETE_SERVICE_PROVIDER_SUCCESS](state, action) {
    return Object.assign({}, state, {
      deleteServiceProviderResult: action.payload,
      error: null,
    });
  },
  [types.DELETE_SERVICE_PROVIDER_ERROR](state, action) {
    return Object.assign({}, state, {
      deleteServiceProviderResult: null,
      error: action.payload,
    });
  },

  [types.SERVICE_PROVIDER_STATE_CLEAR](state, action) {
    return Object.assign({}, state, {
      addServiceProvideresult: null,
      error: null,
      updateServiceProvideresult: null,
      deleteServiceProvider: null,
      loading: false,
    });
  },
});
