import createReducer from "../../reducers/createReducer";
import * as types from "./types";
let initialState = {
  orderList: [],
  loading: true,
  error: null,
  orderDeatils: null,
  statusResult: null
};

export const orderReducer = createReducer(initialState, {
  [types.GET_ORDER_LIST](state, action) {
    return Object.assign({}, state, {
      orderList: action.payload,
      error: null,
      loading: false,
    });
  },
  [types.GET_ORDER_LIST_ERROR](state, action) {
    return Object.assign({}, state, {
      error: action.payload,
      orderList: [],
      loading: false,
    });
  },
  [types.GET_ORDER_LIST_LOADING](state, action) {
    return Object.assign({}, state, {
      loading: true,
    });
  },
  [types.GET_ORDER](state, action) {
    return Object.assign({}, state, {
      orderDeatils: action.payload,
      error: null,
      loading: false,
    });
  },
  [types.GET_ORDER_ERROR](state, action) {
    return Object.assign({}, state, {
      error: action.payload,
      orderDeatils: [],
      loading: false,
    });
  },
  [types.GET_ORDER_LOADING](state, action) {
    return Object.assign({}, state, {
      loading: true,
    });
  },
  [types.UPDATE_ORDER_STATUS](state, action) {
    return Object.assign({}, state, {
      statusResult: action.payload,
      error: null,
      loading: false,
    });
  },
  [types.UPDATE_ORDER_STATUS_ERROR](state, action) {
    return Object.assign({}, state, {
      error: action.payload,
      statusResult: [],
      loading: false,
    });
  },
});
