import createReducer from "../../reducers/createReducer";
import * as types from "./types";
let initialState = {
  loginResult: null,
  error: null,
  loading: false,
};

export const loginReducer = createReducer(initialState, {
  [types.LOGIN_USER_SUCCESS](state, action) {
    return Object.assign({}, state, {
      loginResult: action.payload,
      error: null,
      loading: false,
    });
  },
  [types.LOGIN_USER_ERROR](state, action) {
    return Object.assign({}, state, {
      error: action.payload,
      loginResult: null,
      loading: false,
    });
  },
  [types.LOGIN_USER_LOADING](state, action) {
    return Object.assign({}, state, {
      error: null,
      loginResult: null,
      loading: true,
    });
  },
  [types.LOGIN_USER_CLEAR](state, action) {
    return Object.assign({}, state, {
      error: null,
      loginResult: null,
      loading: false,
      loggedinUserDetails: null,
    });
  },
  [types.LOGGEDIN_USER_SUCCESS](state, action) {
    return Object.assign({}, state, {
      loggedinUserDetails: action.payload,
      error: null,
      loading: false,
    });
  },
  [types.LOGGEDIN_USER_ERROR](state, action) {
    return Object.assign({}, state, {
      error: action.payload,
      loggedinUserDetails: null,
      loading: false,
    });
  },
});
