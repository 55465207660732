// GET CARTYPE LIST
export const GET_CAR_TYPES = "GET_CAR_TYPES";
export const GET_CAR_TYPES_ERROR = "GET_CAR_TYPES_ERROR";
export const GET_CAR_TYPES_LOADING = "GET_CAR_TYPES_LOADING";
// CLEAR REDUCER STATE
export const CARTYPE_STATE_CLEAR = "CARTYPE_STATE_CLEAR";
// ADD CARTYPE
export const ADD_CARTYPE_SUCCESS = "ADD_CARTYPE_SUCCESS";
export const ADD_CARTYPE_ERROR = "ADD_CARTYPE_ERROR";
//UPDATE CARTYPE
export const UPDATE_CARTYPE_SUCCESS = "UPDATE_CARTYPE_SUCCESS";
export const UPDATE_CARTYPE_ERROR = "UPDATE_CARTYPE_ERROR";
//DELETE CARTYPE
export const DELETE_CARTYPE_SUCCESS = "DELETE_CARTYPE_SUCCESS";
export const DELETE_CARTYPE_ERROR = "DELETE_CARTYPE_ERROR";
