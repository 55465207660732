// GET PRIVACY POLICY CONTENT
export const GET_SETTINGS = "GET_SETTINGS";
export const GET_SETTINGS_ERROR = "GET_SETTINGS_ERROR";
export const GET_SETTINGS_LOADING = "GET_SETTINGS_LOADING";
// CLEAR REDUCER STATE
export const SETTINGS_STATE_CLEAR = "SETTINGS_STATE_CLEAR";
// ADD SETTINGS
export const ADD_SETTINGS_SUCCESS = "ADD_SETTINGS_SUCCESS";
export const ADD_SETTINGS_ERROR = "ADD_SETTINGS_ERROR";
//UPDATE SETTINGS
export const UPDATE_SETTINGS_SUCCESS = "UPDATE_SETTINGS_SUCCESS";
export const UPDATE_SETTINGS_ERROR = "UPDATE_SETTINGS_ERROR";
