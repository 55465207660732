// GET SALES_MANAGERS LIST
export const GET_SALES_MANAGERS = "GET_SALES_MANAGERS";
export const GET_SALES_MANAGERS_ERROR = "GET_SALES_MANAGERS_ERROR";
export const GET_SALES_MANAGERS_LOADING = "GET_SALES_MANAGERS_LOADING";
// CLEAR REDUCER STATE
export const SALAES_MANAGER_STATE_CLEAR = "SALAES_MANAGER_STATE_CLEAR";
// ADD SALAES_MANAGER
export const ADD_SALAES_MANAGER_SUCCESS = "ADD_SALAES_MANAGER_SUCCESS";
export const ADD_SALAES_MANAGER_ERROR = "ADD_SALAES_MANAGER_ERROR";
//UPDATE SALAES_MANAGER
export const UPDATE_SALAES_MANAGER_SUCCESS = "UPDATE_SALAES_MANAGER_SUCCESS";
export const UPDATE_SALAES_MANAGER_ERROR = "UPDATE_SALAES_MANAGER_ERROR";
//DELETE SALAES_MANAGER
export const DELETE_SALAES_MANAGER_SUCCESS = "DELETE_SALAES_MANAGER_SUCCESS";
export const DELETE_SALAES_MANAGER_ERROR = "DELETE_SALAES_MANAGER_ERROR";
//BLOCL SALAES_MANAGER
export const BLOCK_SALAES_MANAGER_SUCCESS = "BLOCK_SALAES_MANAGER_SUCCESS";
export const BLOCK_SALAES_MANAGER_ERROR = "BLOCK_SALAES_MANAGER_ERROR";