import createReducer from "../../reducers/createReducer";
import * as types from "./types";
let initialState = {
  packages: [],
  loading: true,
  addPackageResult: null,
  deletePackageResult: null,
  deletePackageErrorRes: null,
  updatePackageResult: null,
  error: null,
};

export const packagesReducer = createReducer(initialState, {
  [types.GET_PACKAGES](state, action) {
    return Object.assign({}, state, {
      packages: action.payload,
      error: null,
      loading: false,
    });
  },
  [types.GET_PACKAGES_ERROR](state, action) {
    return Object.assign({}, state, {
      error: action.payload,
      packages: [],
      loading: false,
    });
  },
  [types.GET_PACKAGES_LOADING](state, action) {
    return Object.assign({}, state, {
      loading: true,
    });
  },
  [types.ADD_PACKAGES_SUCCESS](state, action) {
    return Object.assign({}, state, {
      addPackageResult: action.payload,
      error: null,
      loading: false,
    });
  },
  [types.ADD_PACKAGES_ERROR](state, action) {
    return Object.assign({}, state, {
      error: action.payload,
      addPackageResult: null,
      loading: false,
    });
  },
  [types.UPDATE_PACKAGE_SUCCESS](state, action) {
    return Object.assign({}, state, {
      error: null,
      updatePackageResult: action.payload,
      loading: false,
    });
  },
  [types.UPDATE_PACKAGE_ERROR](state, action) {
    return Object.assign({}, state, {
      error: action.payload,
      updatePackageResult: null,
      loading: false,
    });
  },
  [types.DELETE_PACKAGE_SUCCESS](state, action) {
    return Object.assign({}, state, {
      deletePackageResult: action.payload,
      error: null,
    });
  },
  [types.DELETE_PACKAGE_ERROR](state, action) {
    return Object.assign({}, state, {
      deletePackageResult: null,
      error: action.payload.message,
      deletePackageErrorRes: action.payload.result,
    });
  },
  [types.PACKAGES_STATE_CLEAR](state, action) {
    return Object.assign({}, state, {
      addPackageResult: null,
      error: null,
      loading: false,
      updatePackageResult: null,
      deletePackageResult: null,
      loading: false,
      deletePackageErrorRes: null,
    });
  },
});
